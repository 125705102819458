<template>
  <div>
    <a-modal
      v-model="visibleModalManageTemplate"
      class="mod"
      :title="
        status.isEdit
          ? 'Edit Template Description'
          : 'Create Template Description'
      "
      @cancel="toggleModalManageTemplate"
      destroyOnClose
      centered
    >
      <form-template
        :dataTemplate="newTemplate"
        @handle-change="handleChange"
        :isEdit="status.isEdit"
      />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageTemplate">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageTemplate"
          @click="handleOkModalManageTemplate"
        >
          {{ status.isEdit ? "Edit" : "Create" }}
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageTemplate('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Template Description</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="templateColumns"
        :data-source="templateDataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <span slot="predicate" slot-scope="value, record">
          {{ record.option || record.predikat.nama }}
        </span>
        <span slot="alias" slot-scope="value, record">
          {{ record.alias || record.predikat.alias }}
        </span>
        <div
          class="d-flex flex-column"
          slot="action"
          slot-scope="value, record"
        >
          <a-button
            @click.prevent="toggleModalManageTemplate('edit', record)"
            class="w-100 mb-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteTemplate(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormTemplate = () => import('@/components/app/Admin/TemplateDescription/FormTemplate')
const templateColumns = [
  {
    title: 'Template Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Predicate',
    dataIndex: 'predicate',
    key: 'predicate',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'predicate' },
  },
  {
    title: 'Alias',
    dataIndex: 'alias',
    key: 'alias',
    width: 200,
    align: 'center',
    scopedSlots: { customRender: 'alias' },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  components: {
    FormTemplate,
  },
  data() {
    return {
      templateColumns,
      templateDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManageTemplate: false,
      loadingActionModalManageTemplate: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      newTemplate: {
        type: null,
        option: null,
        alias: null,
        id_predikat: null,
        description: '',
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload

      if (column === 'variable') {
        this.newTemplate.description += value
      } else {
        if (column === 'type' && ['skill', 'knowledge', 'teacher_note'].includes(value)) {
          this.newTemplate.option = null
          this.newTemplate.alias = null
        } else this.newTemplate.id_predikat = null
        this.newTemplate[column] = value
      }
    },
    toggleModalManageTemplate(status, data) {
      this.visibleModalManageTemplate = !this.visibleModalManageTemplate
      if (!this.visibleModalManageTemplate) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newTemplate = {
            type: null,
            option: null,
            alias: null,
            id_predikat: null,
            description: '',
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newTemplate = {
            type: data.type,
            option: data.option,
            alias: data.alias,
            id_predikat: data.id_predikat,
            description: data.description,
          }
        }
      }
    },
    handleOkModalManageTemplate() {
      const content = `${this.status.isEdit ? 'Are you sure want edit this template? your changes will be affected to report system' : 'Are you sure want to create this template? your changes will be save and affected to report system'}`
      if (this.isValidateForm) {
        this.$notification.warning({
          message: 'Warning',
          description: 'Please fill all column',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageTemplate = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataTemplate: this.newTemplate,
            } : {
              dataTemplate: this.newTemplate,
            }
            this.$store.dispatch(`admin/${this.status.isEdit ? 'EDIT' : 'POST'}_TEMPLATE`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageTemplate = false
                this.toggleModalManageTemplate()
                this.fetchDataTemplate()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Template has been edited' : 'Template has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Template has not been edited' : 'Template has not been created'
                  this.$notification.error({
                    message: 'Error',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteTemplate(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this template ? your changes will be affected to report system</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.$store.dispatch('admin/DELETE_TEMPLATE', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataTemplate()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Template has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Template has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataTemplate(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.loadingTable = true
      this.$store.dispatch('admin/FETCH_TEMPLATE', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.loadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          this.templateDataTable = res.map(el => {
            return {
              key: el.id,
              ...el,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataTemplate()
  },
  computed: {
    isValidateForm() {
      // if (this.newTemplate.type && this.newTemplate.option && this.newTemplate.alias && this.newTemplate.description) {
      const isTypeForPredicate = ['skill', 'knowledge', 'teacher_note'].includes(this.newTemplate.type)
      if (this.newTemplate.type && this.newTemplate.description && ((isTypeForPredicate && this.newTemplate.id_predikat) || (!isTypeForPredicate && this.newTemplate.option && this.newTemplate.alias))) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
